import store from '@/store'
import commonStoreModule from '../commonStoreModule'

export default function waybillJs() {
  const COMMON_STORE_MODULE_NAME = 'common'

  // Register module
  if (!store.hasModule(COMMON_STORE_MODULE_NAME)) store.registerModule(COMMON_STORE_MODULE_NAME, commonStoreModule)

  const fetchCountryList = (success, fail) => {
    store
      .dispatch('common/fetchCountryList')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const sendVerificationCode = (mobile, success, fail) => {
    store
      .dispatch('common/sendVerificationCode', mobile)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  return {
    fetchCountryList,
    sendVerificationCode,
  }
}
